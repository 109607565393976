import Carousel from 'react-material-ui-carousel'
import { Paper, Button } from '@mui/material'

interface IItem {
  name: string;
  description: string;
}

export default function Example() {
  var items: IItem[] = [
    {
      name: "Raj Mohan",
      description: "Probably the most random thing you have ever seen!"
    },
    {
      name: "Prabha Mohan",
      description: "Hello World!"
    },
    {
      name: "Nitesh Mohan",
      description: "Hello World!"
    },
    {
      name: "Roshni Mohan",
      description: "Hello World!"
    }
  ]

  return (
    <Carousel
      autoPlay={true}
      animation="slide"
      indicators={false}
      duration={1000}
      navButtonsAlwaysVisible={false}
      navButtonsAlwaysInvisible={true}
      cycleNavigation={true}
      fullHeightHover={true}
      swipe={true}
    >
      {
        items.map((item, i) => <Item key={i} item={item} />)
      }
    </Carousel>
  )
}

function Item(props: { item: IItem }) {
  return (
    <Paper>
      <h2>{props.item.name}</h2>
      <p>{props.item.description}</p>

      <Button className="CheckButton">
        Check it out!
      </Button>
    </Paper>
  )
}
