import { Box, Card, CardContent, Stack, Typography } from "@mui/material";
import { Helmet } from "react-helmet-async";
import nool from "../../assets/images/nool.png"


export default function Nool() {
  return (
    <div>
      <Helmet>
        <title>NOOL</title>
      </Helmet>
      <Stack m={3} direction="row" spacing={3}>
        <Card sx={{ minWidth: 300, maxWidth: 300, minHeight: 400 }}>
          <Stack m={3} alignItems="center" justifyContent="center" direction="row" spacing={3}>
            <Box component="img" sx={{ height: 50, width: 50 }} alt="" src={nool} />
          </Stack>
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              NOOL
            </Typography>
            <Typography variant="body2" color="text.secondary">
              blah blah
            </Typography>
          </CardContent>
        </Card>
      </Stack>
    </div>
  )
}

