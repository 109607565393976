import { AppBar, Box, Container, Stack, Typography } from '@mui/material';
import nitrouilogo from '../assets/images/nitoruilogo.png';

export default function Header() {
  return (
    <header>
      <AppBar position="static">
        <Box display="flex" alignContent={"center"} alignItems={"center"} justifyContent={"center"}>
          <Stack direction={"column"}>
            <Box component={"img"} src={nitrouilogo} />
            <Typography m="auto" color={"black"}>it's the begining</Typography>
          </Stack>
        </Box>
      </AppBar>
    </header>
  )
}
