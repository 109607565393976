import Example from '../components/Scroller'
import './App.css'

export default function Footer() {
  return (
    <footer>
      <Example />
    </footer>
  )
}
