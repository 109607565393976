import { Routes, Route } from "react-router-dom";
import LeftSideBar from "./LeftSidebar";
import RightSideBar from "./RightSidebar";
import Home from "../screens/home/Home";
import Theriyuma from "../screens/theriyuma/Theriyuma";
import TheriyumaPrivacyPolicy from "../screens/theriyuma/PrivacyPolicy";
import Nool from "../screens/nool/Nool";

import './App.css';

const NotFound = () => {
  return <div>Page not found!</div>
}

export default function Main() {
  return (
    <main>
      <LeftSideBar />
      <Routes>
        <Route path="/theriyuma" element={<Theriyuma />} />
        <Route path="/theriyuma/PrivacyPolicy" element={<TheriyumaPrivacyPolicy />} />
        <Route path="/nool" element={<Nool />} />
        <Route path="/" element={<Home />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <RightSideBar />
    </main>
  )
}
