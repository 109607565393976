import { Box, Card, CardContent, Stack, Typography } from "@mui/material";
import { Helmet } from "react-helmet-async";
import theriyuma from "../../assets/images/Theriyuma.png"


export default function TheriyumaPrivacyPolicy() {
  return (
    <div>
      <Helmet>
        <title>NOOL</title>
      </Helmet>
      <Card sx={{ minWidth: 300, maxWidth: 300, minHeight: 400 }}>
        <Stack m={3} alignItems="center" justifyContent="center" direction="row" spacing={3}>
          <Box component="img" sx={{ height: 50, width: 50 }} alt="" src={theriyuma} />
        </Stack>
        <CardContent>
          <Typography gutterBottom variant="h4" component="div">
            THERIYUMA
          </Typography>
          <Typography variant="h5" color="text.secondary">
            Privacy Policy
          </Typography>
          <Typography variant="subtitle1" color="text.secondary">
            This privacy policy ("Policy") describes how we collect, use, and share non-personal information from users of our Theriyuma app.
          </Typography>

          <Typography variant="h5" color="text.secondary">
            Information we collect
          </Typography>

          <Typography variant="subtitle1" color="text.secondary">
            We do not collect or store any personal information from users, such as names or contact information. We do not collect non-personal information such as device information, app usage data, and analytics.
          </Typography>

          <Typography variant="h5" color="text.secondary">
            Sharing information
          </Typography>

          <Typography variant="subtitle1" color="text.secondary">
            We do not share non-personal information with third-party service providers.
          </Typography>

          <Typography variant="h5" color="text.secondary">
            Third-party services
          </Typography>

          None.

          <Typography variant="h5" color="text.secondary">
            Changes to this policy
          </Typography>

          <Typography variant="subtitle1" color="text.secondary">
            We may update this Policy from time to time. If we make significant changes, we will notify users by email or by posting a notice on our website.

            <Typography variant="h5" color="text.secondary">
              Contact us
            </Typography>

            If you have any questions or concerns about this Policy, please contact us at nitroui@hotmail.com
          </Typography>
        </CardContent>
      </Card>
    </div>
  )
}

