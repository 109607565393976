import './App.css';
import { AppBar, Box, Container, Divider, Stack, Toolbar, Typography } from '@mui/material';

import nool from './assets/images/nool.png';
import theriyuma from './assets/images/Theriyuma.png';
import Header from './Header';
import Main from './Main';
import Footer from './Footer';



function App() {
  return (
    <div className='nitroui-app'>
      <Header />
      <Main />
      <Footer />
    </div>
  );
}

export default App;
