import { Box, Stack } from "@mui/material";
import { Helmet } from "react-helmet-async";
import nool from "../../assets/images/nool.png"
import theriyuma from "../../assets/images/Theriyuma.png"
import { Link as RouterLink, useNavigate, useSearchParams } from 'react-router-dom';
import { useEffect } from "react";


export default function Home() {
  const navigate = useNavigate();
  let [searchParams] = useSearchParams();

  const route = searchParams?.get("route");

  useEffect(() => {
    if (route) navigate(route);
  }, [navigate, route]);

  if (route) {
    return null;
  }

  return (
    <div>
      <Helmet>
        <title>HOME</title>
      </Helmet>
      <Stack m={3} direction="row" spacing={3}>
        <RouterLink to="/nool">
          <Box component="img" sx={{ height: 50, width: 50 }} alt="" src={nool} />
        </RouterLink>
        <RouterLink to="/theriyuma">
          <Box component="img" sx={{ height: 50, width: 50 }} alt="" src={theriyuma} />
        </RouterLink>
      </Stack>
    </div>
  )
}
