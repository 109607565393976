import { Box, Card, CardContent, Stack, Typography } from "@mui/material";
import { Helmet } from "react-helmet-async";
import theriyuma from "../../assets/images/Theriyuma.png"


export default function Theriyuma() {
  return (
    <div>
      <Helmet>
        <title>THERIYUMA</title>
      </Helmet>
      <Card sx={{ minWidth: 300, maxWidth: 300, minHeight: 400 }}>
        <Stack m={3} alignItems="center" justifyContent="center" direction="row" spacing={3}>
          <Box component="img" sx={{ height: 50, width: 50 }} alt="" src={theriyuma} />
        </Stack>
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            THERIYUMA
          </Typography>
          <Typography variant="body2" color="text.secondary">
            blah blah
          </Typography>
        </CardContent>
      </Card>
    </div>
  )
}

